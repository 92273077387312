import React from "react"
import SEO from "../components/seo"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
import axios from "axios"
export default class Contact extends React.Component {
  state = {
    isSubmitted: false,
    name: {
      value: "",
      error: true,
    },
    email: {
      value: "",
      error: true,
    },
    message: {
      value: "",
      error: true,
    },
  }

  componentDidMount(): void {
    const script = document.createElement("script")

    script.src = withPrefix("js/show-on-scroll.js")

    script.async = true

    document.body.appendChild(script)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleInputChange = event => {
    let error = false
    event.preventDefault()

    const { id, value } = event.target

    if (!value) {
      error = true
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.setState({ [id]: { value, error } }, () => {})
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleSubmit = event => {
    this.setState({ isSubmitted: true })

    event.preventDefault()

    if (
      this.state?.name?.error ||
      this.state?.email?.error ||
      this.state?.message?.error
    ) {
      return
    }

    const payload = {
      name: this.state.name.value,
      email: this.state.email.value,
      message: this.state.message.value,
    }

    axios
      .post(`https://cms-admin.documentesign.com/contacts`, payload)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(response => {
        this.setState({
          isSubmitted: false,
          name: { value: "", error: true },
          email: { value: "", error: true },
          message: { value: "", error: true },
        })
      })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Contact Us | Document eSign Help Center"
          description="Get help from the Document eSign support team"
        />
        <div className="intro contact-intro bg-blue-light ani-in show-on-scroll">
          <div className="container text-center">
            <h1 className="text-center pt-4 pt-lg-5 mt-4">
              Let us hear from you directly!
            </h1>

            <p className="mb-5 mt-3 pb-4 pb-lg-0">
              Got questions? We’re always here to help you
            </p>
          </div>
        </div>
        <div className="py-5 my-lg-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2  pt-lg-5 contact-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group ani-in show-on-scroll">
                    <label htmlFor="name">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Karla Root"
                      value={this.state.name.value}
                      onChange={this.handleInputChange}
                    />
                    {this.state.isSubmitted && this.state.name.error && (
                      <span className="invalid-feedback d-block">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="form-group ani-in show-on-scroll">
                    <label htmlFor="email">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="karla@gmail.com"
                      value={this.state.email.value}
                      onChange={this.handleInputChange}
                    />
                    {this.state.isSubmitted && this.state.email.error && (
                      <span className="invalid-feedback d-block">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div className="form-group ani-in show-on-scroll">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      id="message"
                      placeholder="Enter your message"
                      rows={3}
                      value={this.state.message.value}
                      onChange={this.handleInputChange}
                    ></textarea>
                    {this.state.isSubmitted && this.state.message.error && (
                      <span className="invalid-feedback d-block">
                        This field is required
                      </span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark mt-4"
                    disabled={
                      this.state?.name?.error || this.state?.email?.error
                    }
                  >
                    Send message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
